import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';

import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { ChartDisplayLayout } from './components/ChartDisplayLayout';
import { ChartViewPanel } from './components/ChartViewPanel';
import { ChartViewPanelOptionsValue } from 'types/api/Tenant/AROChart/ChartViewTypes';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import {
  generateBreadCumbItemsV2,
  TenantHomeDashboardNavigationBar,
} from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { useGetBreadcumbItems } from 'services/v1/Common/NavBarMenuService';
import { useGetChartViewLayout } from 'services/v1/Tenant/AROChart/ChartViewService';
import { ChartViewType } from 'constant/ChartViewConstant';

export default function AROChartViewPage() {
  const { chartViewId } = useParams<{ chartViewId: string }>();

  const [optionValue, setOptionValue] = useState<ChartViewPanelOptionsValue>({
    members: null,
    accumulation: null,
    measure: null,
    layer: null,
  });

  const { data: breadCrumbItems } = useGetBreadcumbItems(chartViewId, NAVIGATION_COMPONENT_TYPE.CHART);

  const breadCrumbs = useMemo(() => {
    return generateBreadCumbItemsV2(breadCrumbItems?.data || []);
  }, [breadCrumbItems?.data]);

  const { data: chartViewData, isLoading: isLoadingChartViewData } = useGetChartViewLayout({
    chartViewId,
  });

  const chartsLayout = useMemo(() => {
    if (chartViewData?.data) {
      return {
        values: chartViewData?.data?.charts,
        layouts: JSON.parse(chartViewData?.data?.layout),
      };
    }
    return {
      values: [],
      layouts: { lg: [] },
    };
  }, [chartViewData?.data]);

  const memberIds: string[] = useMemo(() => {
    if (optionValue.members) {
      return optionValue.members.map((item) => item.value.toString());
    }
    return [];
  }, [optionValue.members]);

  const handleOnOptionChange = (value: ChartViewPanelOptionsValue) => {
    setOptionValue(value);
  };
  return (
    <TenantHomeDashboardNavigationBar
      containerSx={{ height: '100%', flex: 1, flexDirection: 'column', overflowY: 'auto', display: 'flex' }}
      rootContainerSx={{ height: '95vh' }}
      breadCrumbs={breadCrumbs}
    >
      <ChartViewPanel onOptionChange={handleOnOptionChange} />
      {isLoadingChartViewData && (
        <Stack height={550}>
          <ActivityIndicator />
        </Stack>
      )}

      <ChartDisplayLayout
        layouts={chartsLayout?.layouts}
        values={chartsLayout?.values}
        generalFilter={{
          memberIds,
          cubeAttributeId: optionValue.measure?.value?.toString() ?? '',
          accumulationType: optionValue.accumulation?.value?.toString() ?? '',
          insightViewAttributeId:
            chartViewData?.data.chartViewType === ChartViewType.MULTI_MEMBER_SELECTION
              ? optionValue.layer?.value?.toString()
              : '',
        }}
      />
    </TenantHomeDashboardNavigationBar>
  );
}
