import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  ChartViewDefinitionDetails,
  ChartViewDefinitionRowItem,
  ChartViewDefintionInsightViewDropdown,
  RequestCreateChartViewDefinition,
} from 'types/api/SystemTenant/AROChart/ChartDefinitionTypes';

export const CHART_VIEW_QUERY_KEY = {
  CHART_VIEW_DEFINITION_INSIGHT_VIEW_DROPDOWN: 'chart-view-definition-insight-view-dropdown',
  CHART_VIEW_DEFINITION_DETAILS: 'chart-view-definition-details',
  CHART_VIEW_DEFINITION_LIST: 'chart-view-definition-list',
};

export function useGetChartViewInsightDropdown() {
  return useQuery<AxiosResponse<ChartViewDefintionInsightViewDropdown>, AxiosDefaultErrorEntity>(
    [CHART_VIEW_QUERY_KEY.CHART_VIEW_DEFINITION_INSIGHT_VIEW_DROPDOWN],
    () => axios.get(`/api/v1/chart-definition/insight-view/dropdown`).then((res) => res)
  );
}

export function useUpsertChartViewDefinition() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestCreateChartViewDefinition>(
    (data) => axios.post(`/api/v1/chart-definition/upsert`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CHART_VIEW_QUERY_KEY.CHART_VIEW_DEFINITION_LIST);
        queryClient.invalidateQueries(CHART_VIEW_QUERY_KEY.CHART_VIEW_DEFINITION_DETAILS);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(CHART_VIEW_QUERY_KEY.CHART_VIEW_DEFINITION_DETAILS);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}

export function useGetListChartView() {
  return useQuery<AxiosResponse<ChartViewDefinitionRowItem[]>, AxiosDefaultErrorEntity>(
    [CHART_VIEW_QUERY_KEY.CHART_VIEW_DEFINITION_LIST],
    () => axios.get(`/api/v1/chart-definition/list`).then((res) => res)
  );
}

export function useGetChartViewDetails(chartId?: string) {
  return useQuery<AxiosResponse<ChartViewDefinitionDetails>, AxiosDefaultErrorEntity>(
    [CHART_VIEW_QUERY_KEY.CHART_VIEW_DEFINITION_DETAILS, chartId],
    () => axios.get(`/api/v1/chart-definition/details/${chartId}`).then((res) => res),
    {
      enabled: !!chartId,
    }
  );
}
