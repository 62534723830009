export const ChartViewType = {
  SINGLE_MEMBER_SELECTION: 'SINGLE_MEMBER_SELECTION',
  MULTI_MEMBER_SELECTION: 'MULTI_MEMBER_SELECTION',
};

export const ChartType = {
  LINE: 'LINE',
  BAR: 'BAR',
  PIE: 'PIE',
  ROW: 'ROW',
  GAUGE: 'GAUGE',
};
