import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import {
  GridRowModesModel,
  GridRowModes,
  GridSelectionModel,
  GridRowId,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  GridActionsCellItem,
  GridColumns,
} from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import { CustomDataTable } from 'components/DatatableComponent';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { useGetListChartView } from 'services/v1/SystemTenant/AROChart/ChartViewDefinitionService';
import { ChartViewDefinitionRowItem } from 'types/api/SystemTenant/AROChart/ChartDefinitionTypes';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import { PATH_CONSTANT } from 'constant/PathConstant';

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

export default function ChartViewDefinitionList() {
  const { dashboardViewMode } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<ChartViewDefinitionRowItem[]>([]);
  const navigate = useNavigate();
  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 0,
    size: 20,
    filterValue: '',
    sortType: 'asc',
    filterOperator: 'contains',
  });

  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);

  const { data: chartViewDefinitionList, isLoading } = useGetListChartView();
  const handleEditClick = (id: GridRowId) => () => {
    navigate(PATH_CONSTANT.SYSTEM.CHART.EDIT_CHART_DEFINITION.replace(':chartViewId', id.toString()));
  };

  const isAnyEdit = useMemo(() => {
    return rowModesModel && Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);

  const isBuilderViewOnly = dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY;

  const columns = useMemo<GridColumns>(() => {
    const cols: GridColumns = [
      {
        field: 'chartViewName',
        headerName: 'Chart View Name',
        width: 250,
      },
      {
        field: 'chartViewType',
        headerName: 'Chart View Type',
        width: 200,
      },
      {
        field: 'insightName',
        headerName: 'Insight Name',
        width: 300,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'cubeName',
        headerName: 'Cube',
        width: 300,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
    ];

    if (isBuilderViewOnly) return cols;

    return [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        cellClassName: 'actions',

        getActions: ({ id }) => {
          return [
            // @ts-ignore
            <GridActionsCellItem
              disabled={isAnyEdit}
              icon={<EditIcon />}
              key={id}
              label='Edit'
              onClick={handleEditClick(id)}
            />,
          ];
        },
      },
      ...cols,
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBuilderViewOnly]);

  useEffect(() => {
    if (chartViewDefinitionList?.data) {
      setRowData(chartViewDefinitionList?.data);
      setRowModesModel(
        chartViewDefinitionList?.data?.reduce((acc, item) => {
          return { ...acc, [item.id]: GridRowModes.View };
        }, {})
      );
    }
  }, [chartViewDefinitionList]);

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnAddButtonClick = () => {
    navigate(PATH_CONSTANT.SYSTEM.CHART.CREATE_CHART_DEFINITION);
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort });
  };
  return (
    <MainLayoutComponent pageTitle='Chart View Definition' breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), 'Chart View']}>
      <CustomDataTable
        initialState={{
          pagination: {
            page: 0,
          },
        }}
        loading={isLoading}
        checkboxSelection={false}
        rows={rowData}
        columns={columns}
        rowCount={chartViewDefinitionList?.data?.length ?? 0}
        page={filter.page || 0}
        pageSize={filter.size}
        autoHeight={false}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSortModelChange={handleOnSortModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        onSelectionModelChange={(selectedId: GridSelectionModel) => {
          setSelectedDataID(selectedId);
        }}
        paginationMode='client'
        sortingMode='client'
        filterMode='client'
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setFilterButtonElement: setFilterButtonElement,
            disabled: isLoading || isBuilderViewOnly,
            disabledDeleteButton: selectedDataID.length === 0,

            config: {
              onInsertClick: handleOnAddButtonClick,
              deleteButtonText: 'Delete',
              fieldFocusOnInsert: '',
              insertButtonText: 'Add New Chart View',
              insertInitialObject: {
                layerName: '',
                isNew: true,
              },
            },
          },
        }}
      />
    </MainLayoutComponent>
  );
}
