import { Autocomplete, Box, Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { ChartItemComponentTypes } from './ChartViewEditor';
import { useMemo } from 'react';
import { getChartIconByType, getChartLabelByType } from './ChartItemComponent';
import { ChartType } from 'constant/ChartViewConstant';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

interface ChartEditorComponentFormProps {
  index: number;
  onDelete: (index: number) => void;
  formik: ReturnType<
    typeof useFormik<{
      charts: ChartItemComponentTypes[];
    }>
  >;
}

const AXIS_OPTIONS = [
  {
    value: 'STATIC_TIME_PERIODS',
    label: 'Static Time Periods',
  },
  {
    value: 'DATA_TYPE',
    label: 'Data Type',
  },
];

export function ChartEditorComponentForm(props: ChartEditorComponentFormProps) {
  const { formik, index } = props;

  const xAxisOptions = useMemo(() => {
    if (index === null) return AXIS_OPTIONS;
    return AXIS_OPTIONS.filter((option) => option.value !== formik.values.charts[index]?.yAxis?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.charts[index]?.yAxis, index]);

  const yAxisOptions = useMemo(() => {
    if (index === null) return AXIS_OPTIONS;
    return AXIS_OPTIONS.filter((option) => option.value !== formik.values.charts[index]?.xAxis?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.charts[index]?.xAxis, index]);

  if (index === null) {
    return null;
  }

  const handleOnDeleteClick = () => {
    const confirm = window.confirm('Are you sure you want to delete this chart?');
    if (confirm) {
      props.onDelete(index);
    }
  };

  return (
    <Stack width='100%' spacing={2}>
      <Stack direction='row' alignItems='center' spacing={1} width='100%' gap={1}>
        <Box
          sx={{
            width: 70,
            height: 50,
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #e8eced',
          }}
        >
          {getChartIconByType(formik.values.charts[index]?.chartType)}
        </Box>
        <Stack>
          <Typography variant='input-label' sx={{ color: '#000', fontSize: 11 }}>
            Component
          </Typography>
          <Typography variant='input-label' sx={{ color: '#000', fontWeight: 800 }}>
            {getChartLabelByType(formik.values.charts[index]?.chartType)}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack width='100%'>
        <Typography variant='input-label' sx={{ color: '#000', fontWeight: 800 }}>
          Chart Title
        </Typography>
        <Typography variant='input-label-gray' fontSize={13}></Typography>
        <TextField
          sx={textInputStyles}
          name={`charts[${index}].name`}
          value={formik.values.charts[index]?.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.charts && formik.touched.charts[index]?.name}
          helperText={formik.touched.charts && formik.touched.charts[index]?.name && formik.errors.charts}
          hiddenLabel
          variant='outlined'
          size='small'
          placeholder='e.g. Interest Function for Loan'
        />
      </Stack>
      <Stack width='100%'>
        <Typography variant='input-label' sx={{ color: '#000', fontWeight: 800 }}>
          Description
        </Typography>
        <Typography variant='input-label-gray' fontSize={13}></Typography>
        <TextField
          sx={textInputStyles}
          name={`charts[${index}].description`}
          value={formik.values.charts[index]?.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.charts && formik.touched.charts[index]?.description}
          helperText={formik.touched.charts && formik.touched.charts[index]?.description && formik.errors.charts}
          hiddenLabel
          variant='outlined'
          size='small'
          placeholder='e.g. Interest Function for Loan'
        />
      </Stack>
      {(formik.values.charts[index]?.chartType === ChartType.BAR ||
        formik.values.charts[index]?.chartType === ChartType.LINE) && (
        <>
          <Stack width='100%'>
            <Typography variant='input-label' fontWeight={800}>
              X-axis
            </Typography>
            <Autocomplete
              options={xAxisOptions}
              onChange={(event, value) => {
                formik.setFieldValue(`charts[${index}].xAxis`, value);
              }}
              value={formik.values.charts[index].xAxis}
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              sx={{ ...autocompleteStyles, width: '100%' }}
              renderInput={(params) => <TextField {...params} name='subject' placeholder='Select Subject...' />}
            />
          </Stack>
          <Stack width='100%'>
            <Typography variant='input-label' fontWeight={800}>
              Y-axis
            </Typography>
            <Autocomplete
              value={formik.values.charts[index].yAxis}
              options={yAxisOptions}
              onChange={(event, value) => {
                formik.setFieldValue(`charts[${index}].yAxis`, value);
              }}
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              sx={{ ...autocompleteStyles, width: '100%' }}
              renderInput={(params) => <TextField {...params} name='subject' placeholder='Select Subject...' />}
            />
          </Stack>
        </>
      )}
      {formik.values?.charts?.[index]?.chartType === ChartType.GAUGE && (
        <Stack width='100%' gap={1}>
          <Typography variant='input-label' fontWeight={800} mb={2}>
            Gauge Ranges
          </Typography>
          {formik.values?.charts?.[index]?.gaugeParams?.length > 0 &&
            formik.values?.charts?.[index]?.gaugeParams?.map((gauge, gaugeIndex) => (
              <Stack flexDirection='row' gap={1}>
                <Stack width='20%'>
                  <TextField
                    sx={textInputStyles}
                    name={`charts[${index}].gaugeParams[${gaugeIndex}].color`}
                    hiddenLabel
                    value={formik.values.charts[index].gaugeParams[gaugeIndex].color}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant='outlined'
                    size='small'
                    type='color'
                    placeholder='Min'
                  />
                </Stack>
                <Stack width='30%'>
                  <TextField
                    sx={textInputStyles}
                    name={`charts[${index}].gaugeParams[${gaugeIndex}].min`}
                    hiddenLabel
                    value={formik.values.charts[index].gaugeParams[gaugeIndex].min}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant='outlined'
                    size='small'
                    type='number'
                    placeholder='Min'
                  />
                </Stack>
                <Stack width='30%'>
                  <TextField
                    sx={textInputStyles}
                    name={`charts[${index}].gaugeParams[${gaugeIndex}].max`}
                    hiddenLabel
                    value={formik.values.charts[index].gaugeParams[gaugeIndex].max}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant='outlined'
                    size='small'
                    type='number'
                    placeholder='Max'
                  />
                </Stack>
              </Stack>
            ))}
        </Stack>
      )}
      <Divider />
      <Stack width='100%' justifyContent='flex-end' gap={1}>
        <Button onClick={handleOnDeleteClick} startIcon={<DeleteOutlinedIcon />} variant='outlined' color='error'>
          Delete
        </Button>
      </Stack>
    </Stack>
  );
}
