import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import { Responsive, WidthProvider, Layouts } from 'react-grid-layout';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { ChartItemComponent } from './ChartItemComponent';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

export type ChartItemType = {
  id: string;
  name: string;
  chartType: string;
  description: string;
  xAxis: AutoCompleteItem;
  yAxis: AutoCompleteItem;
};

type ChartDisplayPreviewType = {
  values: ChartItemType[];
  layouts: Layouts;
};

export function ChartDisplayPreview(props: Readonly<ChartDisplayPreviewType>) {
  const { values, layouts: chartLayouts } = props;
  const [layouts, setLayouts] = useState<Layouts>({ lg: [] });
  const [charts, setCharts] = useState<ChartItemType[]>([]);

  const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
  const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };

  useEffect(() => {
    setLayouts(chartLayouts);
    setCharts(values);
  }, [values, chartLayouts]);

  return (
    <Stack height='100%' direction='row' px={2} py={1} overflow='hidden' gap={2}>
      <Stack width='100%'>
        {charts.length === 0 && (
          <Box sx={{ height: '90vh', width: '100%', borderRadius: 3, bgcolor: '#e8eced' }}>
            <Stack alignItems='center' justifyContent='center' height='100%' width='100%' direction='column'>
              <Typography variant='h5' component='h5' sx={{ color: '#999999' }}>
                Chart View Display
              </Typography>
              <Typography variant='caption' component='h5' sx={{ color: '#999999' }}>
                No chart view configured, build your chart view by clicking on the "Open Chart View Editor" button.
              </Typography>
            </Stack>
          </Box>
        )}
        {charts.length > 0 && (
          <ResponsiveGridLayout
            className='layout'
            style={{
              backgroundColor: '#e8eced',
              height: '90vh',
              width: '100%',
              borderRadius: 3,
            }}
            layouts={layouts}
            breakpoints={breakpoints}
            cols={cols}
            isResizable={false}
            isDraggable={false}
            isDroppable={false}
            rowHeight={30}
            width={1200}
          >
            {charts.map((item, idx) => (
              <div key={item.id}>
                <ChartItemComponent chartType={item.chartType} title={item.name} />
              </div>
            ))}
          </ResponsiveGridLayout>
        )}
      </Stack>
    </Stack>
  );
}
