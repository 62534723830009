import { useEffect, useMemo, useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import { RectangleCheckBoxComponent } from 'components/Form/RectangleCheckBoxComponent';
import { useGetAllMyViewItemList } from 'services/v1/Tenant/ConfigurationService';
import {
  MyViewDashboardDefinitionItem,
  MyViewItemConfig,
  MyViewSelectionItem,
} from 'types/api/Tenant/ConfigurationTypes';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { matchSorter } from 'match-sorter';
import { Chip } from '@mui/material';

const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  bgcolor: 'background.paper',
  maxHeight: '80vh',
  minHeight: '80vh',
  borderRadius: 1,
  py: 2,
};
const textInputStyles = {
  width: '100%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalSelectMyViewItemProps {
  visible?: boolean;
  onClose?: () => void;
  onSave?: (data: MyViewSelectionItem[]) => void;
  selectedItemIds: Set<string>;
  selectedDashboard: MyViewDashboardDefinitionItem | null;
}

const getComponentTypeLabel = (componentType: string) => {
  switch (componentType) {
    case 'K_TREE':
      return 'K-Tree';
    case 'SUBJECT':
      return 'Subject';
    case 'EVENT':
      return 'Event';
    case 'CUBE':
      return 'Cube';
    case 'INSIGHT_VIEW':
      return 'Insight View';
    case 'STANDARD_VIEW':
      return 'Standard View';
    case 'DASHBOARD':
      return 'Dashboard';
    case 'CHART':
      return 'Chart';
    default:
      return '';
  }
};

function ReorderList(props: { items: MyViewItemConfig[]; onReorder: (items: MyViewItemConfig[]) => void }) {
  const { items } = props;
  return (
    <Stack>
      <Typography variant='input-label-gray' mb={1} sx={{ fontSize: 12 }}>
        Click and drag to reorder the columns
      </Typography>

      <Stack>
        {items.map((item, index) => (
          <Stack
            key={item.id}
            direction='row'
            alignItems='center'
            justifyContent={'space-between'}
            gap={1}
            sx={{
              mb: 1,
              border: `1px solid #E0E0E0`,
              borderRadius: 2,
              padding: 1,
              cursor: 'move',
            }}
          >
            <Stack direction='row' alignItems='center' gap={1} sx={{ flex: 1 }}>
              <DragIndicatorRoundedIcon sx={{ color: '#98A2AE' }} />
              <Typography
                variant='input-label'
                letterSpacing={0.3}
                textAlign='left'
                sx={{ fontSize: 12, color: '#737373' }}
              >
                {item.displayName}
                <Chip
                  size='small'
                  sx={{ ml: 0.4, fontSize: 9, color: '#737373', backgroundColor: '#F0F0F0', borderRadius: 1 }}
                  label={
                    <Typography variant='input-label' sx={{ fontSize: 9, color: '#737373' }}>
                      {getComponentTypeLabel(item.componentType)}
                    </Typography>
                  }
                />
              </Typography>
            </Stack>
            <IconButton size='small' onClick={() => {}} sx={{ color: '#98A2AE' }}>
              <CloseRoundedIcon />
            </IconButton>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export function ModalSelectMyViewItem(props: Readonly<ModalSelectMyViewItemProps>) {
  const { onClose, visible = false, selectedItemIds, onSave, selectedDashboard } = props;

  const [selectedItem, setSelectedItem] = useState<Set<string>>(new Set());
  const { isLoading, data: myViewItems } = useGetAllMyViewItemList();
  const [searchKeyword, setSearchKeyword] = useState('');

  const items: MyViewItemConfig[] = useMemo(() => {
    if (myViewItems?.data && myViewItems?.data.length > 0) {
      return matchSorter(myViewItems?.data || [], searchKeyword, {
        keys: ['displayName', 'componentType'],
        baseSort: () => 0,
      });
    }
    return [];
  }, [myViewItems?.data, searchKeyword]);

  const selectedItems = useMemo(() => {
    return myViewItems?.data?.filter((item) => selectedItem.has(item.id)) || [];
  }, [selectedItem, myViewItems?.data]);

  const handleOnCloseModal = () => {
    setSelectedItem(selectedItemIds);
    onClose?.();
  };

  const handleOnCancel = () => {
    handleOnCloseModal();
  };
  const handleOnSave = () => {
    const data =
      myViewItems?.data
        ?.filter((item) => selectedItem.has(item.id))
        .map((item) => {
          if (item.subjectId) {
            const id = item.id.split('#')[0];
            return {
              id,
              componentName: item.displayName,
              componentType: item.componentType,
              subjectId: item.subjectId,
              ktreeDefinitionId: item.ktreeDefinitionId,
              ktreeName: item.ktreeName,
              cubeDefinitionId: item.cubeDefinitionId,
            };
          }
          return {
            id: item.id,
            componentType: item.componentType,
            componentName: item.displayName,
            ktreeDefinitionId: item.ktreeDefinitionId,
            ktreeName: item.ktreeName,
            cubeDefinitionId: item.cubeDefinitionId,
          };
        }) || [];

    //@ts-ignore
    onSave?.(data);
    handleOnCloseModal();
  };

  const handleOnClick = (item: MyViewItemConfig) => {
    setSelectedItem((prevState) => {
      if (prevState.has(item.id)) {
        prevState.delete(item.id);
      } else {
        prevState.add(item.id);
      }

      return new Set(prevState);
    });
  };

  const handleOnOrder = (items: MyViewItemConfig[]) => {};

  useEffect(() => {
    setSelectedItem(new Set(selectedItemIds));
  }, [selectedItemIds]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle} px={2} justifyContent='space-between' flex={1} height='100%'>
          <Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='center'>
              <Stack width='70%'>
                <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                  Add/Remove myView Items
                </Typography>
                <Typography variant='input-label-gray' mb={1}>
                  Select the items you want to assign to {selectedDashboard?.dashboardName}
                </Typography>
              </Stack>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 1 }} />
          </Stack>

          <Stack
            height='100%'
            width='100%'
            direction='row'
            justifyContent='space-between'
            gap={4}
            sx={{
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <Stack sx={{ height: '100%', width: '68%' }} spacing={1}>
              <TextField
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                name='search'
                sx={textInputStyles}
                size='small'
                placeholder='Search name or component...'
                type='text'
                InputProps={{
                  endAdornment: (
                    <CancelIcon
                      sx={{
                        display: searchKeyword ? 'block' : 'none',
                        fontSize: 14,
                        color: '#98A2AE',
                        cursor: 'pointer',
                      }}
                      onClick={() => setSearchKeyword('')}
                    />
                  ),
                }}
              />
              <Stack sx={{ padding: 1, border: '1px solid rgb(221, 221, 221)', bgcolor: 'rgb(248, 250, 252)' }}>
                <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#000' }} mb={3}>
                  Pages
                </Typography>
                {isLoading ? (
                  <Stack height={450}>
                    <ActivityIndicator />
                  </Stack>
                ) : (
                  <Stack
                    direction='row'
                    gap={1}
                    sx={{
                      borderRadius: 1,
                      display: 'flex',
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      gap: 1,
                    }}
                  >
                    {items.map((item) => (
                      <RectangleCheckBoxComponent
                        searchValue={searchKeyword}
                        checked={selectedItem.has(item.id)}
                        key={item.id}
                        secondLabel={getComponentTypeLabel(item.componentType)}
                        text={item.displayName}
                        onClick={() => handleOnClick(item)}
                      />
                    ))}
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack width='30%'>
              <ReorderList items={selectedItems} onReorder={handleOnOrder} />
            </Stack>
          </Stack>
          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading variant='main-table-panel' onClick={() => handleOnSave()}>
                Assign
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
