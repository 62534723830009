import * as Yup from 'yup';
import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Layouts } from 'react-grid-layout';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonLoading from '@mui/lab/LoadingButton';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { ChartItemComponentTypes, ChartViewEditor } from './components/ChartViewEditor';
import { ChartDisplayPreview } from './components/ChartDisplayPreview';
import { ToggleButtonGroupSX } from 'utils/CommonSx';
import { ChartType, ChartViewType } from 'constant/ChartViewConstant';
import { CubeDefinitionDetails } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { RequestCreateChartViewDefinition } from 'types/api/SystemTenant/AROChart/ChartDefinitionTypes';
import {
  useGetStandardViewDefinitionCubeDropdown,
  useGetStandardViewDefinitionSubjectDropdown,
} from 'services/v1/SystemTenant/AROView/StandardViewDefinitionService';
import {
  useGetChartViewInsightDropdown,
  useUpsertChartViewDefinition,
} from 'services/v1/SystemTenant/AROChart/ChartViewDefinitionService';

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};
const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export interface CreateInsightViewDefinitionFormikInitialValues {
  name: string | null;
  subject: AutoCompleteItem | null;
  insightView: AutoCompleteItem | null;
  cube: AutoCompleteItem | null;
  type: string;
}
export const createInsightViewDefinitionFormikInitialValues: CreateInsightViewDefinitionFormikInitialValues = {
  name: null,
  subject: null,
  cube: null,
  insightView: null,
  type: ChartViewType.SINGLE_MEMBER_SELECTION,
};

export const createInsightViewDefinitionValidationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required').nullable(),
  subject: Yup.object().nullable(),
  cube: Yup.object().required('This field is required').nullable(),
  insightView: Yup.object().required('This field is required').nullable(),
  type: Yup.string().required('This field is required').nullable(),
});

const CHART_VIEW_TYPE_OPTIONS = [
  {
    label: 'Single Member Selection',
    value: ChartViewType.SINGLE_MEMBER_SELECTION,
  },
  {
    label: 'Multiple Member Selection',
    value: ChartViewType.MULTI_MEMBER_SELECTION,
  },
];

export default function CreateChartDefinition() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openChartViewEditor, setOpenChartViewEditor] = React.useState(false);
  const [chartViewComponents, setChartViewComponents] = React.useState<ChartItemComponentTypes[]>([]);
  const [layoutChart, setLayoutChart] = React.useState<Layouts>({ lg: [] });

  const formik = useFormik({
    initialValues: createInsightViewDefinitionFormikInitialValues,
    validationSchema: createInsightViewDefinitionValidationSchema,
    onSubmit: () => {},
  });

  const { mutate: upsertChartViewDefinition, isLoading: isSubmitting } = useUpsertChartViewDefinition();

  const { data: subjectDropdownOptions, isLoading: isLoadingSubjectOptions } =
    useGetStandardViewDefinitionSubjectDropdown();

  const { data: insightViewDropdownOptions, isLoading: isLoadingInsightViewOptions } = useGetChartViewInsightDropdown();
  const { data: cubeDropdownOptions, isLoading: isLoadingCubeOptions } = useGetStandardViewDefinitionCubeDropdown(
    formik.values.subject?.value?.toString() ?? ''
  );

  const handleOnSubmit = () => {
    if (formik.values.name) {
      const payload: RequestCreateChartViewDefinition = {
        type: formik.values.type,
        name: formik.values.name,
        insightViewId: formik.values.insightView?.value?.toString() ?? '',
        cubeId: formik.values.cube?.value?.toString() ?? '',
        chartViewLayouts: JSON.stringify(layoutChart),
        chartItems: chartViewComponents.map((chart) => {
          const item = {
            name: chart.name,
            chartIndexCode: chart.id,
            chartType: chart.chartType,
            description: chart.description,
            params: JSON.stringify({
              xAxis: chart.xAxis,
              yAxis: chart.yAxis,
            }),
          };

          if (chart.chartType === ChartType.GAUGE) {
            item.params = JSON.stringify(chart.gaugeParams);
          }

          return item;
        }),
      };

      console.log({
        payload,
      });
      upsertChartViewDefinition(payload, {
        onSuccess: () => {
          toast.success('Create Chart View Definition successfully!');
          navigate(PATH_CONSTANT.SYSTEM.CHART.CHART_DEFINITION);
        },
      });
    }
  };

  const handleOnOpenChartViewEditor = () => {
    setOpenChartViewEditor(true);
  };

  const handleOnCancel = () => {
    navigate(PATH_CONSTANT.SYSTEM.CHART.CHART_DEFINITION);
  };

  const handleOnCubeChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('cube', value);
  };

  const insightCubeDefinition: CubeDefinitionDetails | null = useMemo(() => {
    return (
      insightViewDropdownOptions?.data.mapInsightViewWithCubeDefinition[
        formik.values.insightView?.value?.toString() ?? ''
      ] ?? null
    );
  }, [formik.values.insightView, insightViewDropdownOptions?.data.mapInsightViewWithCubeDefinition]);

  const handleOnInsightViewChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('insightView', value);
  };

  const handleOnSubjectChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('subject', value);
  };

  const handleOnChartViewChange = (data: { charts: ChartItemComponentTypes[]; layouts: Layouts }) => {
    setChartViewComponents(data.charts);
    setLayoutChart(data.layouts);
  };

  useEffect(() => {
    if (insightCubeDefinition) {
      formik.setFieldValue('cube', {
        label: insightCubeDefinition.cubeName,
        value: insightCubeDefinition.id,
      });
    } else {
      formik.setFieldValue('cube', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightCubeDefinition]);

  const isAllFormValid = createInsightViewDefinitionValidationSchema.isValidSync(formik.values);
  const isLoadingOptionsData = isLoadingSubjectOptions;

  return (
    <MainLayoutComponent
      pageTitle='Add New Chart Definition'
      breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), 'Chart Definition']}
    >
      <Box p={2} bgcolor='#fff'>
        {isLoadingOptionsData ? (
          <Stack height={450}>
            <ActivityIndicator />
          </Stack>
        ) : (
          <>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Chart View Definition
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Stack gap={2}>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Chart View Name
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}></Typography>
                <TextField
                  sx={textInputStyles}
                  name='name'
                  hiddenLabel
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant='outlined'
                  size='small'
                  placeholder='e.g. Chart 1'
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Chart View Type
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the cube from which you want to calculate the values for.
                </Typography>
                <ToggleButtonGroup
                  color='primary'
                  exclusive
                  value={formik.values.type}
                  onChange={(e, value) => {
                    if (!value) return;
                    formik.setFieldValue('type', value);
                  }}
                  sx={ToggleButtonGroupSX.buttonGroup}
                >
                  {CHART_VIEW_TYPE_OPTIONS.map((option) => (
                    <ToggleButton key={option.value} value={option.value} size='small'>
                      {option.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>

              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Insight View
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the cube from which you want to calculate the values for.
                </Typography>
                <Autocomplete
                  options={insightViewDropdownOptions?.data.data ?? []}
                  disabled={isLoadingInsightViewOptions}
                  getOptionLabel={(option) => option.label}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  value={formik.values.insightView}
                  onBlur={formik.handleBlur}
                  onChange={handleOnInsightViewChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='cube'
                      placeholder='Select Insight...'
                      error={formik.touched.insightView && Boolean(formik.errors.insightView)}
                      helperText={formik.touched.insightView && formik.errors.insightView}
                    />
                  )}
                />
              </Stack>
              {!insightCubeDefinition && formik.values.insightView && (
                <Stack width='40%'>
                  <Typography variant='input-label' fontWeight={800}>
                    Select Subject
                  </Typography>
                  <Typography variant='input-label-gray' fontSize={13}>
                    Choose the subject (table) from which you want to pull the values.
                  </Typography>
                  <Autocomplete
                    options={subjectDropdownOptions?.data ?? []}
                    getOptionLabel={(option) => option.label}
                    sx={{ ...autocompleteStyles, width: '100%' }}
                    clearIcon={null}
                    value={formik.values.subject}
                    onBlur={formik.handleBlur}
                    onChange={handleOnSubjectChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='subject'
                        placeholder='Select Subject...'
                        error={formik.touched.subject && Boolean(formik.errors.subject)}
                        helperText={formik.touched.subject && formik.errors.subject}
                      />
                    )}
                  />
                </Stack>
              )}
              {formik.values.insightView && (
                <Stack width='40%'>
                  <Typography variant='input-label' fontWeight={800}>
                    Select Cube
                  </Typography>
                  <Typography variant='input-label-gray' fontSize={13}>
                    Choose the cube from which you want to calculate the values for.
                  </Typography>
                  <Autocomplete
                    options={cubeDropdownOptions?.data ?? []}
                    disabled={isLoadingCubeOptions || Boolean(insightCubeDefinition)}
                    getOptionLabel={(option) => option.label}
                    sx={{ ...autocompleteStyles, width: '100%' }}
                    clearIcon={null}
                    value={formik.values.cube}
                    onBlur={formik.handleBlur}
                    onChange={handleOnCubeChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='cube'
                        placeholder='Select Cube...'
                        error={formik.touched.cube && Boolean(formik.errors.cube)}
                        helperText={formik.touched.cube && formik.errors.cube}
                      />
                    )}
                  />
                </Stack>
              )}
            </Stack>
            {formik.values.insightView && formik.values.cube && (
              <>
                <Stack flexDirection='row' justifyContent='space-between' alignItems='center' mt={3}>
                  <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                    Chart View Display
                  </Typography>
                  <ButtonLoading
                    variant='main-table-panel-border'
                    startIcon={<TrendingUpIcon />}
                    onClick={handleOnOpenChartViewEditor}
                  >
                    Open Chart View Editor
                  </ButtonLoading>
                </Stack>
                <Divider sx={{ my: 1 }} />
                <ChartDisplayPreview values={chartViewComponents} layouts={layoutChart} />
              </>
            )}
            <Stack direction='row' px={2} justifyContent='space-between' alignItems='center'>
              <Stack />
              <Stack direction='row' gap={2}>
                <ButtonLoading variant='main-table-panel-border' onClick={handleOnCancel}>
                  Cancel
                </ButtonLoading>
                <ButtonLoading
                  variant='main-table-panel'
                  loading={isSubmitting}
                  onClick={handleOnSubmit}
                  disabled={!isAllFormValid}
                >
                  Save
                </ButtonLoading>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
      <ChartViewEditor
        chartViewName={formik.values.name ?? ''}
        open={openChartViewEditor}
        onClose={() => setOpenChartViewEditor(false)}
        onChanges={handleOnChartViewChange}
      />
    </MainLayoutComponent>
  );
}
