import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import axios from 'libs/Axios';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  ChartDisplay,
  ChartViewPanelOptions,
  ResponseChartViewDisplay,
} from 'types/api/Tenant/AROChart/ChartViewTypes';
import { createTreeData, sortTreeData } from 'utils/Object';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { ENV_CONFIG } from 'config/env';

export const CHART_VIEW_DISPLAY_QUERY_KEY = {
  CHART_VIEW_DISPLAY: 'chart-view-display',
  CHART_VIEW_ITEM_DATA: 'chart-view-item-data',
  CHART_VIEW_PANLE_OPTIONS: 'chart-view-panel-options',
};

export function useGetChartViewLayout(payload: { chartViewId?: string }) {
  return useQuery<AxiosResponse<ResponseChartViewDisplay>, AxiosDefaultErrorEntity>(
    [CHART_VIEW_DISPLAY_QUERY_KEY.CHART_VIEW_DISPLAY, payload],
    () => axios.get(`/api/v1/tenant/chart-view/display/${payload.chartViewId}`).then((res) => res),
    {
      enabled: !!payload.chartViewId,
    }
  );
}

export function useGetChartItemData(payload: {
  chartViewItemId?: string;
  memberIds: string[];
  cubeAttributeId: string;
  accumulationType: string;
  filters: AutoCompleteItem[];
  insightViewAttributeId?: string;
}) {
  return useQuery<AxiosResponse<ChartDisplay>, AxiosDefaultErrorEntity>(
    [CHART_VIEW_DISPLAY_QUERY_KEY.CHART_VIEW_ITEM_DATA, payload],
    () =>
      axios
        .post(`/api/v1/tenant/chart-view/data/${payload.chartViewItemId}`, {
          members: payload.memberIds,
          cubeAttributeId: payload.cubeAttributeId,
          accumulationType: payload.accumulationType,
          insightAttributeId: payload.insightViewAttributeId,
          filters: payload.filters ?? [],
        })
        .then((res) => res),
    {
      enabled:
        !!payload.chartViewItemId &&
        !!payload.memberIds &&
        payload.memberIds.length > 0 &&
        !!payload.cubeAttributeId &&
        !!payload.accumulationType,
      cacheTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
      staleTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
    }
  );
}

export function useGetChartViewPanelOptions(chartViewId?: string) {
  return useQuery<AxiosResponse<ChartViewPanelOptions>, AxiosDefaultErrorEntity>(
    [CHART_VIEW_DISPLAY_QUERY_KEY.CHART_VIEW_PANLE_OPTIONS, chartViewId],
    () =>
      axios.get(`/api/v1/tenant/chart-view/panel-options/${chartViewId}`).then((res) => {
        const treeData = createTreeData(null, res.data.memberOptions);
        return {
          ...res,
          data: {
            ...res.data,
            memberOptions: sortTreeData(treeData),
          },
        };
      })
  );
}
