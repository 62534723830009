import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import qs from 'query-string';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import InsightsOutlined from '@mui/icons-material/InsightsOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import PollOutlined from '@mui/icons-material/PollOutlined';
import { useEffect, useMemo, useState } from 'react';
import { Paper, Typography, SvgIcon, Stack, Button, ListItemButton, ListItemText } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as CustomIconSubject } from 'assets/icons/icon-menu-subject.svg';
import { ReactComponent as CustomIconKTree } from 'assets/icons/icon-menu-ktree.svg';
import { ReactComponent as CustomIconEvent } from 'assets/icons/icon-menu-event.svg';
import { ReactComponent as CustomIconCube } from 'assets/icons/icon-menu-cube.svg';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { MyViewDashboardItem, QuickAccessItem } from 'types/api/Tenant/ConfigurationTypes';
import { useGetMyPersonalization } from 'services/v1/Tenant/ConfigurationService';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { RoleType } from 'store/reducer/authReducer';
import { ROLES } from 'constant/PermissonConstant';

const getIcon = (type: NAVIGATION_COMPONENT_TYPE) => {
  switch (type) {
    case NAVIGATION_COMPONENT_TYPE.K_TREE:
      return (
        <SvgIcon
          fontSize='medium'
          sx={{ color: '#42BB93', fill: 'transparent' }}
          inheritViewBox
          component={CustomIconKTree}
        />
      );
    case NAVIGATION_COMPONENT_TYPE.SUBJECT:
      return (
        <SvgIcon
          fontSize='medium'
          sx={{ color: '#42BB93', fill: 'transparent' }}
          inheritViewBox
          component={CustomIconSubject}
        />
      );
    case NAVIGATION_COMPONENT_TYPE.EVENT:
      return (
        <SvgIcon
          fontSize='medium'
          sx={{ color: '#42BB93', fill: 'transparent' }}
          inheritViewBox
          component={CustomIconEvent}
        />
      );
    case NAVIGATION_COMPONENT_TYPE.CUBE:
      return <SvgIcon fontSize='medium' sx={{ color: '#42BB93' }} inheritViewBox component={CustomIconCube} />;
    case NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW:
      return (
        <InsightsOutlined
          fontSize='medium'
          sx={{
            color: '#42BB93',
          }}
        />
      );
    case NAVIGATION_COMPONENT_TYPE.STANDARD_VIEW:
      return (
        <PollOutlined
          fontSize='medium'
          sx={{
            color: '#42BB93',
          }}
        />
      );
    case NAVIGATION_COMPONENT_TYPE.DASHBOARD:
      return (
        <DashboardOutlinedIcon
          fontSize='medium'
          sx={{
            color: '#42BB93',
          }}
        />
      );
    default:
      return null;
  }
};

export const getQuickAccessNavigateURL = (
  item?: QuickAccessItem | null,
  withProxyMemberParam?: boolean,
  navigationRef?: string,
  role?: RoleType | ''
): string | null => {
  let URL = null;
  if (!item) return URL;
  switch (item.componentType) {
    case NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW:
      URL = `${PATH_CONSTANT.TENANT.CUBE.INSIGHT.replace(':cubeId', item.cubeId).replace(
        ':insightId',
        item.value
      )}?${qs.stringify({
        memberId: item.memberId,
        defaultXDimension: item.defaultDimensionFilter?.defaultXDimension,
        defaultYDimension: item.defaultDimensionFilter?.defaultYDimension,
        defaultFilterMember: item.defaultDimensionFilter?.defaultFilterMember,
        myViewId: item.viewId,
        workFlowId: item.drillDownWorkflows[0]?.id,
        proxyMainMemberId: withProxyMemberParam ? item.memberId : undefined,
      })}`;
      break;
    case NAVIGATION_COMPONENT_TYPE.CHART:
      URL = `${PATH_CONSTANT.TENANT.CHART.CHART_VIEW.replace(':chartViewId', item.value)}?${qs.stringify({
        memberId: item.memberId,
        defaultXDimension: item.defaultDimensionFilter?.defaultXDimension,
        defaultYDimension: item.defaultDimensionFilter?.defaultYDimension,
        defaultFilterMember: item.defaultDimensionFilter?.defaultFilterMember,
        myViewId: item.viewId,
        workFlowId: item.drillDownWorkflows[0]?.id,
        proxyMainMemberId: withProxyMemberParam ? item.memberId : undefined,
      })}`;
      break;
    case NAVIGATION_COMPONENT_TYPE.STANDARD_VIEW:
      URL = `${PATH_CONSTANT.TENANT.VIEW.STANDARD_VIEW.replace(':viewId', item.value)}?${qs.stringify({
        memberId: item.memberId,
        myViewId: item.viewId,
      })}`;
      break;

    case NAVIGATION_COMPONENT_TYPE.CUBE:
      if (role === ROLES.MANAGER) {
        URL = `${PATH_CONSTANT.TENANT.CUBE.CUBE_DIMENSIONAL_VIEW.replace(':cubeId', item.cubeId)}?${qs.stringify({
          memberId: item.memberId,
          myViewId: item.viewId,
          ref: navigationRef,
          workflowId: item.drillDownWorkflows[0]?.id,
        })}`;
      } else {
        URL = `${PATH_CONSTANT.TENANT.CUBE.CUBE.replace(':cubeId', item.cubeId)}?${qs.stringify({
          memberId: item.memberId,
          myViewId: item.viewId,
          ref: navigationRef,
          workflowId: item.drillDownWorkflows[0]?.id,
        })}`;
      }
      break;

    case NAVIGATION_COMPONENT_TYPE.DASHBOARD:
      URL = `${PATH_CONSTANT.TENANT.DASHBOARD.DASHBOARD.replace(':dashboardId', item.title)}?${qs.stringify({
        memberId: item.memberId,
        myViewId: item.viewId,
      })}`;
      break;
    case NAVIGATION_COMPONENT_TYPE.EVENT:
      URL = `${PATH_CONSTANT.TENANT.EVENT.EVENT.replace(':subjectId', item.value)}?${qs.stringify({
        refSubjectId: item.subjectId,
        myViewId: item.viewId,
        ref: navigationRef,
      })}`;
      break;
    case NAVIGATION_COMPONENT_TYPE.SUBJECT:
      URL = `${PATH_CONSTANT.TENANT.KMS.DATA_INPUT.replace(':subjectId', item.value)}?${qs.stringify({
        myViewId: item.viewId,
        ref: navigationRef,
      })}`;
      break;

    case NAVIGATION_COMPONENT_TYPE.K_TREE:
      URL = `${PATH_CONSTANT.TENANT.KMS.KTREE_DATA.replace(':kTreeId', item.value)}?${qs.stringify({
        myViewId: item.viewId,
        ref: navigationRef,
      })}`;
      break;

    default:
      break;
  }
  return URL;
};

const MyViewItemButton = ({ item, onClick }: { item: QuickAccessItem; onClick: (item: QuickAccessItem) => void }) => {
  return (
    <Button
      key={item.value}
      sx={{
        width: 'auto',
        minHeight: 50,
        border: '1px solid #E0E0E0',
        alignItems: 'center',
        display: 'flex',
        textTransform: 'none',
        flexDirection: 'row',
        paddingX: 2,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          border: '1px solid #42BB93',
        },
      }}
      onClick={() => {
        onClick(item);
      }}
    >
      <Stack width={40}>{getIcon(item.componentType)}</Stack>
      <Stack width='auto' direction='column' alignItems='flex-start'>
        <Typography variant='body2' fontWeight='bold' textAlign='left'>
          {item.title}
        </Typography>
        <Typography variant='body2' color='text.secondary' textAlign='left'>
          {item.secondaryTitle}
        </Typography>
        <Typography variant='body2' color='text.secondary' fontStyle='italic'>
          {item.memberName}
        </Typography>
      </Stack>
    </Button>
  );
};

export function QuickAccessBox(props: { onClose?: () => void }) {
  const { onClose } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const myViewId = searchParams.get('myViewId');
  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });

  const [selectedDashboardId, setSelectedDasboardId] = useState<string | null>(null);
  const [selectedSolutionGroupId, setSelectedSolutionGroupId] = useState<string | null>(null);
  const handleOnNavigate = (item: QuickAccessItem) => {
    const navigateURL = getQuickAccessNavigateURL(item);
    if (navigateURL) {
      navigate(navigateURL);
    }
    onClose?.();
  };

  const mapMyViewIdWithDashboardId = useMemo(() => {
    const map = new Map();
    if (allMyViewsData?.data) {
      allMyViewsData.data.dashboards.forEach((dashboard) => {
        dashboard.myViews?.forEach((myView) => {
          map.set(myView.viewId, dashboard.id);
        });
      });
    }
    return map;
  }, [allMyViewsData?.data]);

  const mapDashboardIdWithSolutionGroup = useMemo(() => {
    const map = new Map();
    if (allMyViewsData?.data) {
      allMyViewsData.data.dashboards.forEach((dashboard) => {
        map.set(dashboard.id, dashboard.solutionDefinition.id);
      });
    }
    return map;
  }, [allMyViewsData?.data]);

  const mapDashboardWithMyView = useMemo(() => {
    const map = new Map();
    if (allMyViewsData?.data) {
      allMyViewsData.data.dashboards.forEach((dashboard) => {
        map.set(dashboard.id, dashboard.myViews);
      });
    }

    return map;
  }, [allMyViewsData?.data]);

  const mapSolutionWithDashboard = useMemo(() => {
    const map = new Map();
    if (allMyViewsData?.data) {
      allMyViewsData.data.solutions.forEach((solution) => {
        const dashboards = allMyViewsData.data.dashboards.filter((dsb) => dsb.solutionDefinition.id === solution.value);
        map.set(solution.value.toString(), dashboards);
      });
    }

    return map;
  }, [allMyViewsData?.data]);

  const myViewDisplayList: QuickAccessItem[] = useMemo(() => {
    if (selectedDashboardId) {
      return mapDashboardWithMyView.get(selectedDashboardId);
    }
    return [];
  }, [selectedDashboardId, mapDashboardWithMyView]);

  const myViewDashboardList: MyViewDashboardItem[] = useMemo(() => {
    if (selectedSolutionGroupId) {
      return mapSolutionWithDashboard.get(selectedSolutionGroupId);
    }
    return [];
  }, [selectedSolutionGroupId, mapSolutionWithDashboard]);

  const handleOnDashboardSelect = (item: MyViewDashboardItem) => {
    setSelectedDasboardId(item.id);
  };

  const handleOnSolutionGroupSelect = (item: AutoCompleteItem) => {
    setSelectedSolutionGroupId(item.value.toString());
    const dashboardList = mapSolutionWithDashboard.get(item.value);
    setSelectedDasboardId(dashboardList[0].id);
  };

  useEffect(() => {
    if (allMyViewsData?.data?.dashboards && allMyViewsData?.data?.dashboards.length > 1) {
      const initialDashboardId = allMyViewsData?.data.dashboards[0].id;
      setSelectedDasboardId(initialDashboardId);
      const initialSolutionGroupId = mapDashboardIdWithSolutionGroup.get(initialDashboardId);
      setSelectedSolutionGroupId(initialSolutionGroupId);
    }
  }, [allMyViewsData?.data, mapDashboardIdWithSolutionGroup]);
  useEffect(() => {
    if (mapMyViewIdWithDashboardId.has(myViewId)) {
      const selectedDashboardId = mapMyViewIdWithDashboardId.get(myViewId);
      setSelectedDasboardId(selectedDashboardId);
      const selectedSolutionId = mapDashboardIdWithSolutionGroup.get(selectedDashboardId);
      setSelectedSolutionGroupId(selectedSolutionId);
    }
  }, [myViewId, mapMyViewIdWithDashboardId, mapDashboardIdWithSolutionGroup]);

  return (
    <Paper sx={{ width: '100%', p: 2 }} variant='outlined'>
      <Stack direction='row' gap={1} mb={1}>
        <StarBorderOutlinedIcon
          fontSize='medium'
          sx={{
            color: '#42BB93',
          }}
        />
        <Typography variant='body1' fontWeight='bold' color='text.primary'>
          myView Dashboards
        </Typography>
      </Stack>

      <Divider sx={{ mb: 1 }} />
      {allMyViewsData?.data.displayType === 'DASHBOARD_LIST' && (
        <Stack direction='row' gap={1} minHeight={280}>
          <Stack maxWidth='18%'>
            <List
              component='nav'
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
              }}
            >
              {allMyViewsData?.data?.solutions?.map((item, index) => (
                <ListItemButton
                  onClick={(event) => handleOnSolutionGroupSelect(item)}
                  disableRipple
                  key={item.value}
                  selected={selectedSolutionGroupId === item.value}
                  sx={{
                    py: 0,
                    px: 2,
                    borderLeft: selectedSolutionGroupId === item.value ? '3px solid #3B4797' : '3px solid transparent',
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant='input-label' textAlign='left' fontSize={13}>
                        {item.label}
                      </Typography>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </Stack>
          <Divider orientation='vertical' variant='middle' flexItem />
          <Stack maxWidth='18%'>
            <List
              component='nav'
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
              }}
            >
              {myViewDashboardList?.map((item, index) => (
                <ListItemButton
                  onClick={(event) => handleOnDashboardSelect(item)}
                  disableRipple
                  key={item.id}
                  selected={selectedDashboardId === item.id}
                  sx={{
                    py: 0,
                    px: 2,
                    borderLeft: selectedDashboardId === item.id ? '3px solid #42BB93' : '3px solid transparent',
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant='input-label' textAlign='left' fontSize={13}>
                        {item.dashboardName}
                      </Typography>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </Stack>
          <Divider orientation='vertical' variant='middle' flexItem />
          <Stack width='63%'>
            <List
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                flexWrap: 'wrap',
              }}
            >
              {myViewDisplayList?.map((item, index) => (
                <MyViewItemButton item={item} onClick={() => handleOnNavigate(item)} key={item.viewId} />
              ))}
            </List>
          </Stack>
        </Stack>
      )}

      {allMyViewsData?.data.displayType === 'MY_VIEW_LIST' && (
        <Stack width='100%'>
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              flexWrap: 'wrap',
            }}
          >
            {allMyViewsData.data.myViewItems?.map((item, index) => (
              <MyViewItemButton item={item} onClick={() => handleOnNavigate(item)} key={item.viewId} />
            ))}
          </List>
        </Stack>
      )}
    </Paper>
  );
}
