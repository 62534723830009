import { Box, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { EChartComponent } from 'pages/Tenant/AroDashboard/GaugeChart';
import { useGetChartViewDetails } from 'services/v1/SystemTenant/AROChart/ChartViewDefinitionService';
import { useGetCubeDefinitionDetails } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { useGetChartItemData } from 'services/v1/Tenant/AROChart/ChartViewService';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

export function ChartItem(props: {
  chartItemId: string;
  memberIds: string[];
  cubeAttributeId: string;
  accumulationType: string;
  insightViewAttributeId?: string;
}) {
  const { chartViewId } = useParams<{ chartViewId: string }>();
  const { data: chartViewDefintition } = useGetChartViewDetails(chartViewId);
  const { data: cubeDefinitionDetails } = useGetCubeDefinitionDetails(
    chartViewDefintition?.data.cube?.value?.toString()
  );

  const kTreeName = useMemo(() => {
    if (cubeDefinitionDetails?.data) {
      return cubeDefinitionDetails?.data.member.label;
    }
    return null;
  }, [cubeDefinitionDetails?.data]);

  const { chartItemId, memberIds, cubeAttributeId, accumulationType, insightViewAttributeId } = props;
  const [chartFilters, setChartFilters] = useState<{ [key: string]: AutoCompleteItem }>({});
  const { data: chartDisplayData, isLoading: isLoadingChart } = useGetChartItemData({
    chartViewItemId: chartItemId,
    memberIds,
    cubeAttributeId,
    accumulationType,
    insightViewAttributeId,
    filters: Object.values(chartFilters),
  });

  const handleOnChartFilterChange = (filterName: string, item: AutoCompleteItem) => {
    setChartFilters((prev) => ({
      ...prev,
      [filterName]: {
        value: item.value,
        label: filterName,
      },
    }));
  };

  if (memberIds.length === 0) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#ffffff',
          border: '1px solid #ccc',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant='input-label'>Please select {kTreeName} to view the chart</Typography>
      </Box>
    );
  }

  if (isLoadingChart) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#ffffff',
          border: '1px solid #ccc',
          borderRadius: 2,
        }}
      >
        <ActivityIndicator />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: '#ffffff',
        border: '1px solid #ccc',
        borderRadius: 2,
      }}
    >
      {chartDisplayData?.data && (
        <EChartComponent
          chartDisplayData={chartDisplayData?.data}
          key={chartItemId}
          onFilterChange={handleOnChartFilterChange}
        />
      )}
    </Box>
  );
}
