import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined';
import AlignHorizontalLeftOutlinedIcon from '@mui/icons-material/AlignHorizontalLeftOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import { ChartType } from 'constant/ChartViewConstant';

export function getChartLabelByType(type: string) {
  switch (type) {
    case ChartType.LINE:
      return 'Line Chart';
    case ChartType.BAR:
      return 'Bar Chart';
    case ChartType.PIE:
      return 'Pie Chart';
    case ChartType.ROW:
      return 'Row Chart';
    case ChartType.GAUGE:
      return 'Gauge Chart';
    default:
      return '';
  }
}

export function getChartIconByType(type: string) {
  switch (type) {
    case ChartType.LINE:
      return (
        <StackedLineChartOutlinedIcon
          fontSize='medium'
          sx={{
            color: '#42BB93',
          }}
        />
      );
    case ChartType.BAR:
      return (
        <BarChartOutlinedIcon
          fontSize='medium'
          sx={{
            color: '#42BB93',
          }}
        />
      );
    case ChartType.PIE:
      return (
        <PieChartOutlinedIcon
          fontSize='medium'
          sx={{
            color: '#42BB93',
          }}
        />
      );
    case ChartType.ROW:
      return (
        <AlignHorizontalLeftOutlinedIcon
          fontSize='medium'
          sx={{
            color: '#42BB93',
          }}
        />
      );
    case ChartType.GAUGE:
      return (
        <SpeedOutlinedIcon
          fontSize='medium'
          sx={{
            color: '#42BB93',
          }}
        />
      );
    default:
      return null;
  }
}

export function ChartItemComponent({
  chartType,
  onEdit,
  title,
}: {
  chartType: string;
  title: string;
  onEdit?: () => void;
}) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: '#ffffff',
        border: '1px solid #ccc',
        borderRadius: 2,
      }}
    >
      <Stack p={2} height='100%' justifyContent='space-between'>
        <Stack>
          <Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='input-label-gray' component='h3' fontWeight='bold' sx={{ color: '#000' }}>
              {title}
            </Typography>
            {onEdit && (
              <IconButton
                onClick={onEdit}
                aria-label='delete'
                size='small'
                onMouseDown={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
              >
                <ModeEditOutlineOutlinedIcon fontSize='inherit' />
              </IconButton>
            )}
          </Stack>
          <Divider />
        </Stack>
        <Stack justifyContent='center' alignItems='center' height='100%'>
          {getChartIconByType(chartType)}
          {onEdit && (
            <Typography variant='caption' component='h5' sx={{ color: '#999999' }}>
              Configure your component in the right panel
            </Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
